<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        اضافه جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/mindful')"
        />
      </template>
    </Toolbar>
    <div class="form-card">
      <div class="mb-3 text-center">
        <div class="align-items-center">
          <div class="profile-img-edit align-items-center">
            <img
              :src="body.image"
              class="profile-pic height-150 width-150 align-items-center"
              style="width: 100px;height: 100px;"
              @error="$errorHandler"
            />
            <div class="p-image">
              <div class="position-relative">
                <i class="ri-pencil-line upload-button"></i>
                <b-form-file
                  class="h-100 position-absolute"
                  accept="image/*"
                  style="opacity: 0;"
                  @change="previewImage"
                ></b-form-file>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="name" class="form-label">
          الاسم <span style="color: red">*</span>
        </label>
        <input class="form-control" id="name" v-model="body.name" />
      </div>

      <div class="mb-3">
        <label for="nationalID" class="form-label">
          الرقم القومي <span style="color: red">*</span>
        </label>
        <input class="form-control" id="nationalID" v-model="body.nationalID" />
      </div>

      <!-- <div class="mb-3">
        <label for="teams" class="form-label">
          الفريق <span style="color: red">*</span>
        </label>
        <Fieldset v-for="item of listEdit" :key="item.id">
          <template #legend>
            {{ item.yearId.name }} ({{ item.yearsTeams.length }})
          </template>
          <p v-for="itm of item.yearsTeams" :key="itm.id">
            <input
              type="checkbox"
              name="teams[]"
              v-model="body.teams"
              :value="itm"
            />
            - {{ itm.name }}
          </p>
        </Fieldset>
      </div> -->

      <div class="mb-3 text-center">
        <Button
          label="تعديل"
          icon="pi pi-plus"
          :disabled="disabledAdd"
          class="p-ml-2 p-button-success"
          @click="save()"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        name: null,
        // teams: [],
        image: '',
        nationalID: null,
      },
      listEdit: [],
      leaguesId: null,
      id: null,
      disabledAdd: false,
    };
  },
  methods: {
    previewImage(ev) {
      this.$file2base64(ev, (image) => {
        this.body.image = image;
      });
    },

    save() {
      if (this.body.name && this.body.nationalID ) {
        this.disabledAdd = true;
        this.$http.put(`mindful/${this.id}`, this.body).then(
          () => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            // this.$router.push('/user/mindful/'+this.leaguesId);
          },
          (err) => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجى ملاء الحقول',
          life: 3000,
        });
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`mindful/${this.id}`).then(
        (res) => {
          this.body.name = res.data.name;
          // this.body.teams = res.data.teams;
          this.body.nationalID = res.data.nationalID;
          this.body.image = this.$baseUploadURL + res.data.image;
          this.leaguesId = res.data.leaguesId.id;
          this.$http
            .get(
              `years/findAllYearsAcademie?leaguesId=${res.data.leaguesId.id}&academieId=${res.data.academieId.id}`,
            )
            .then(
              (res2) => {
                this.listEdit = res2.data;
              },
              (err2) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err2.message,
                  life: 3000,
                });
              },
            );
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    } else {
      this.$router.push('/admin/mindful');
    }
  },
};
</script>

<style>
.p-image {
  width: 0;
}
.p-image .custom-file-input {
  text-align: center;
  width: 89vw;
  position: absolute;
  top: -99px;
  height: 105px;
}
</style>
